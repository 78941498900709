import React from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'rebass'

import { FIELD_NAME, I_DONT_KNOW } from './step-form'

export default function IDontKnowButton() {
  const { values, setFieldValue, submitForm } = useFormikContext()
  const name = values[FIELD_NAME]

  return (
    <Button
      variant="outline"
      onClick={() => {
        setFieldValue(name, I_DONT_KNOW)
        submitForm()
      }}
      mt={4}
      ml={3}
    >
      I don't know
    </Button>
  )
}
