import React from 'react'

import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import OtherField from './other-field'
import StepForm from './step-form'
import RadioField from './radio-field'
import SubmitButton from './submit-button'

export default function IndustryStep({ question }) {
  return (
    <StepForm fieldName={fieldNames.industry}>
      <QuestionText>{question}</QuestionText>
      {['Creative', 'Film & TV', 'Retail', 'Technology', 'Finance & Legal', 'Health & Medical'].map(value => (
        <RadioField key={value} value={value} />
      ))}
      <OtherField />
      <SubmitButton />
    </StepForm>
  )
}
