import React from 'react'
import { Button } from 'rebass'

export default function SubmitButton() {
  return (
    <Button variant="primary" type="submit" mt={4}>
      Submit
    </Button>
  )
}
