import React, { useContext, useReducer } from 'react'
import { Box } from 'rebass'

import { COPYWRITING, LOCALISATION, SUBTITLING, TRANSLATION } from '../utils/constants'
import ContentTypeStep from './steps/content-type-step'
import QuoteContext from './context'
import * as fieldNames from './field-names'
import IndustryStep from './steps/industry-step'
import MinutesStep from './steps/minutes-step'
import SubtitlingServicesStep from './steps/subtitling-services-step'
import ResultsForm from './results-form'
import NumberOfEpisodesStep from './steps/number-of-episodes-step'
import GenreStep from './steps/genre-step'
import MediaTypeStep from './steps/media-type-step'
import BackButton from './back-button'
import ServiceStep from './steps/service-step'
import WordCountStep from './steps/word-count-step'
import AdditionalDetailsStep from './steps/additional-details-step'
import TermsAndConditionsStep from './steps/terms-and-conditions-step'

const getCurrentStep = ({ currentIndex, steps }) => {
  return steps[currentIndex] || { index: currentIndex, name: null, value: null }
}

const getFieldValue = ({ steps }) => fieldName => {
  return (steps.find(x => x.name === fieldName) || {}).value
}

function setFieldValue(name, value) {
  return { type: 'setFieldValue', name, value }
}
const back = () => ({ type: 'back' })

function TranslationForm() {
  const { currentStep } = useContext(QuoteContext)

  switch (currentStep.index) {
    case 1:
      return <IndustryStep question="Which subject matter do you need translated?" />
    case 2:
      return <WordCountStep question="How many words do you need translated?" />
    case 3:
      return <AdditionalDetailsStep question="Is there anything else you would like me to know?" />
    default:
      return <ResultsForm />
  }
}

function FilmForm() {
  const { currentStep } = useContext(QuoteContext)

  switch (currentStep.index) {
    case 2:
      return <GenreStep question="What genre is the movie?" />
    case 3:
      return <MinutesStep question="How long is the film (minutes)?" />
    case 4:
      return <SubtitlingServicesStep />
    case 5:
      return <AdditionalDetailsStep question="Is there anything else you would like me to know?" />
    default:
      return <ResultsForm />
  }
}
function TelevisionForm() {
  const { currentStep } = useContext(QuoteContext)

  switch (currentStep.index) {
    case 2:
      return <GenreStep question="What genre is the TV show?" />
    case 3:
      return <NumberOfEpisodesStep />
    case 4:
      return <MinutesStep question="How long is the average episode (minutes)?" />
    case 5:
      return <SubtitlingServicesStep />
    case 6:
      return <AdditionalDetailsStep question="Is there anything else you would like me to know?" />
    default:
      return <ResultsForm />
  }
}
function AdvertForm() {
  const { currentStep } = useContext(QuoteContext)

  switch (currentStep.index) {
    case 2:
      return <IndustryStep question="What industry is the advert for?" />
    case 3:
      return <MinutesStep question="How long is the advert (minutes)?" />
    case 4:
      return <SubtitlingServicesStep />
    case 5:
      return <AdditionalDetailsStep question="Is there anything else you would like me to know?" />
    default:
      return <ResultsForm />
  }
}

function SubtitlingForm() {
  const { currentStep, getFieldValue } = useContext(QuoteContext)

  if (currentStep.index === 1) {
    return <MediaTypeStep />
  }

  switch (getFieldValue(fieldNames.mediaType)) {
    case 'Film':
      return <FilmForm />
    case 'TV':
      return <TelevisionForm />
    case 'Advert':
      return <AdvertForm />
    default:
      return <MediaTypeStep />
  }
}

function LocalisationForm() {
  const { currentStep } = useContext(QuoteContext)

  switch (currentStep.index) {
    case 1:
      return (
        <ContentTypeStep
          question="What kind of content do you need localised?"
          options={['Marketing material', 'Website', 'App']}
        />
      )
    case 2:
      return <IndustryStep question="What industry is the advert for?" />
    case 3:
      return <TermsAndConditionsStep />
    case 4:
      return <WordCountStep question="How many words does your current content consist of (not including T&C)?" />
    case 5:
      return <AdditionalDetailsStep question="Is there anything else you would like me to know?" />
    default:
      return <ResultsForm />
  }
}

function CopywritingForm() {
  const { currentStep } = useContext(QuoteContext)

  switch (currentStep.index) {
    case 1:
      return (
        <ContentTypeStep question="What do you need copy for?" options={['Marketing material', 'Website', 'App']} />
      )
    case 2:
      return <IndustryStep question="What industry is the advert for?" />
    case 3:
      return <WordCountStep question="How many words do you need written?" />
    case 4:
      return <AdditionalDetailsStep question="Is there anything else you would like me to know?" />
    default:
      return <ResultsForm />
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'setFieldValue': {
      const { name, value } = action
      const { currentIndex, steps } = state

      const existingValidSteps = steps.slice(0, currentIndex)
      return {
        currentIndex: currentIndex + 1,
        steps: [...existingValidSteps, { name, value, index: currentIndex }],
      }
    }
    case 'back': {
      const { currentIndex } = state

      return {
        ...state,
        currentIndex: Math.max(currentIndex - 1, 0),
      }
    }
    default:
      return state
  }
}

export default function GetAQuote({ service, sx, ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    currentIndex: service ? 1 : 0,
    steps: [{ name: fieldNames.service, value: service, index: 0 }],
    submitted: false,
  })

  const selectedService = getFieldValue(state)(fieldNames.service)
  const currentStep = getCurrentStep(state)

  const initialStepIndex = service ? 1 : 0
  const isInitialStep = currentStep.index === initialStepIndex

  return (
    <QuoteContext.Provider
      value={{
        state,
        dispatch,
        currentStep,
        getFieldValue: getFieldValue(state),
        setFieldValue: (...props) => dispatch(setFieldValue(...props)),
        back: () => dispatch(back()),
      }}
    >
      <Box width={['100%', '600px']} maxWidth={'600px'} minHeight={'min(100vh, 500px)'} pb={'100px'} sx={sx} {...props}>
        {currentStep.index === 0 ? (
          <ServiceStep />
        ) : (
          <>
            {selectedService === TRANSLATION && <TranslationForm />}
            {selectedService === SUBTITLING && <SubtitlingForm />}
            {selectedService === COPYWRITING && <CopywritingForm />}
            {selectedService === LOCALISATION && <LocalisationForm />}
            {!isInitialStep && <BackButton />}
          </>
        )}
      </Box>
    </QuoteContext.Provider>
  )
}
