import React from 'react'
import { Input } from '@rebass/forms'

import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import SubmitButton from './submit-button'
import IDontKnowButton from './i-dont-know-button'
import StepForm from './step-form'
import { Field } from 'formik'

export default function WordCountStep({ question }) {
  return (
    <StepForm fieldName={fieldNames.wordCount}>
      <QuestionText>{question}</QuestionText>
      <Field as={Input} name={fieldNames.wordCount} type="number" min="1" required />
      <SubmitButton />
      <IDontKnowButton />
    </StepForm>
  )
}
