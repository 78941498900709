import React, { useContext } from 'react'
import QuoteContext from '../context'
import { Box, Button, Text } from 'rebass'

import { COPYWRITING, LOCALISATION, SUBTITLING, TRANSLATION } from '../../utils/constants'
import * as fieldNames from '../field-names'

const BigText = props => (
  <Text as="p" textAlign="center" fontSize={[4, null, 6]} fontWeight={[400, null, 600]} {...props} />
)

export default function ServiceStep() {
  const { setFieldValue } = useContext(QuoteContext)

  return (
    <>
      <BigText mb={5}>Fill in your project details to get an instant estimate,</BigText>
      <Box
        sx={{
          display: 'grid',
          gridGap: 4,
          gridTemplateColumns: ['1fr', '1fr 1fr'],
        }}
      >
        {[TRANSLATION, SUBTITLING, COPYWRITING, LOCALISATION].map(value => (
          <Button
            key={value}
            fontSize={5}
            onClick={() => setTimeout(() => setFieldValue(fieldNames.service, value), 200)}
          >
            {value}
          </Button>
        ))}
      </Box>
      <BigText mt={5}>alternatively,</BigText>
      <Box sx={{ height: '300px', width: '0', borderLeft: 'solid 3px', borderColor: 'gold', margin: 'auto', my: 3 }} />
      <BigText>tell me about it in your own words down here.</BigText>
    </>
  )
}
