import React, { useContext, useState } from 'react'
import { Textarea } from '@rebass/forms'

import QuoteContext from '../context'
import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import SubmitButton from './submit-button'
import { Button } from 'rebass'

export default function AdditionalDetailsStep({ question }) {
  const { currentStep, setFieldValue } = useContext(QuoteContext)
  const [additionalInformation, setAdditionalInformation] = useState(currentStep.value || '')

  return (
    <form
      onSubmit={e => {
        setFieldValue(fieldNames.additionalInformation, additionalInformation)
        e.preventDefault()
      }}
    >
      <QuestionText>{question}</QuestionText>
      <Textarea
        name={fieldNames.additionalInformation}
        height={120}
        onChange={e => setAdditionalInformation(e.target.value)}
      />
      <SubmitButton />
      <Button variant="outline" type="submit" ml={3}>
        Skip
      </Button>
    </form>
  )
}
