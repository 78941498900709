import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Button, Flex, Heading, Link as RawLink, Link, Text } from 'rebass'

import Container from '../components/container'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Headline from '../components/headline'
import Hr from '../components/hr'
import ChevronRight from '../components/icons/chevron-right'
import ChevronLeft from '../components/icons/chevron-left'
import GetAQuote from '../components/get-a-quote/questionnaire'
import { COPYWRITING, LOCALISATION, SUBTITLING, TRANSLATION } from '../components/utils/constants'
import Paragraph from '../components/paragraph'
import Parallax from '../components/parallax'
import Image from 'gatsby-image'

const ServiceLink = props => (
  <Link
    sx={{
      color: 'white',
      fontWeight: 'lighter',
      fontSize: [3, null, 5],
      textDecoration: 'none',
      borderBottom: 'solid 2px transparent',
      ':hover': { borderBottom: 'solid 2px', borderBottomColor: 'gold' },
    }}
    {...props}
  />
)

const VerticalLine = () => <Box sx={{ display: ['none', 'inline'], mx: 3, borderRight: 'solid 2px white' }} />

const ServiceHeading = props => (
  <Container pt={4} pb={0}>
    <Heading
      variant="sectionHeading"
      as="h2"
      mt={5}
      mb={0}
      textAlign={['center', 'left']}
      width={[null, 'fit-content']}
      sx={{ borderBottom: 'solid 2px', borderColor: 'gold' }}
      {...props}
    />
  </Container>
)

const GetAQuoteContainer = ({ children }) => <Container>{children}</Container>

const Slider = ({ children, sx, ...props }) => (
  <Box
    sx={{
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <Box
      sx={{
        scrollSnapType: 'x mandatory',
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        webkitOverflowScrolling: 'touch',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        ...sx,
      }}
      {...props}
    >
      {children}

      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          left: '0',
          right: 'auto',
          bottom: 'auto',
          display: 'flex',
          paddingY: 3,
          paddingX: 1,
          backgroundColor: 'transparent',
          transform: 'translateY(-50%)',
          zIndex: 1000,
        }}
        onClick={e => {
          const elementToScroll = e.currentTarget.parentElement
          const currentLeft =
            elementToScroll.scrollLeft === 0 ? elementToScroll.scrollWidth : elementToScroll.scrollLeft

          elementToScroll.scrollLeft = currentLeft - elementToScroll.clientWidth
        }}
      >
        <ChevronLeft color="dark-grey" title="Slide left" size={[30, null, null, 90]} stroke="white" strokeWidth={3} />
      </Button>
      <Button
        sx={{
          position: 'absolute',
          top: '50%',
          left: 'auto',
          right: '0',
          bottom: 'auto',
          display: 'flex',
          paddingY: 3,
          paddingX: 1,
          backgroundColor: 'transparent',
          transform: 'translateY(-50%)',
          zIndex: 1000,
        }}
        onClick={e => {
          const element = e.currentTarget.parentElement
          const currentLeft =
            Math.abs(element.scrollWidth - element.scrollLeft - element.clientWidth) < 10
              ? 0 - element.clientWidth
              : element.scrollLeft

          element.scrollLeft = currentLeft + element.clientWidth
        }}
      >
        <ChevronRight
          color="dark-grey"
          title="Slide right"
          size={[30, null, null, 90]}
          stroke="white"
          strokeWidth={3}
        />
      </Button>
    </Box>
  </Box>
)

const Slide = ({ children, sx, ...props }) => (
  <Box
    sx={{
      scrollSnapAlign: 'start',
      width: '100%',
      height: ['fit-content', '500px'],
      minHeight: '500px',
      flexShrink: 0,
      scrollBehavior: 'smooth',
      overflow: 'hidden',
      position: 'relative',
      ...sx,
    }}
    {...props}
  >
    {children}
  </Box>
)

export default function Services() {
  const data = useStaticQuery(graphql`
    query {
      headerBackgroundImage: file(relativePath: { eq: "working-on-a-keyboard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      quoteBackgroundImage: file(relativePath: { eq: "subtitling.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      visualDataLogo: file(relativePath: { eq: "visual-data-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Services" />
      <Headline fluidBackgroundImage={data.headerBackgroundImage.childImageSharp.fluid}>
        <Heading variant="headline" as="h1">
          Services provided
        </Heading>
        <Hr color="gold" />
        <Heading
          as="h2"
          fontWeight="lighter"
          fontSize={[3, null, 5]}
          sx={{ justifySelf: ['center', 'flex-start'] }}
          display="inline-flex"
          flexDirection={['column', 'row']}
        >
          <ServiceLink href="#translation">Translation</ServiceLink>
          <VerticalLine />
          <ServiceLink href="#subtitling">Subtitling</ServiceLink>
          <VerticalLine />
          <ServiceLink href="#localisation">Localisation</ServiceLink>
          <VerticalLine />
          <ServiceLink href="#copywriting">Copywriting</ServiceLink>
        </Heading>
      </Headline>

      <ServiceHeading id="translation" mt={0}>
        Translation
      </ServiceHeading>
      <Slider>
        <Slide>
          <Container my={5}>
            <Text
              as="article"
              variant="article"
              sx={{
                maxWidth: '800px',
                fontSize: [null, null, 3],
              }}
            >
              <Paragraph>
                In our globalised world where ideas and information needs to cross borders post-haste, translations of
                documents, articles and website content are becoming ever more sought after. As people and institutions
                want to share knowledge, and companies look to expand into foreign territories, translators bridge the
                gap between original meaning and final understanding.
              </Paragraph>
              <Paragraph>
                These services should be performed with efficiency, accuracy and pragmatism, but also with respect for
                the essential meaning in the source text; which should not be lost on the end-reader. Linguistic talent,
                knowledge of both local cultures and last but not least emotional intelligence are all vital components
                for delivery of high quality translations.
              </Paragraph>

              <Paragraph emphasize>
                If you would like an estimate for the cost of my services, then swipe to the next page to fill in the
                basic details of your project, and I'll get back to you to discuss the particulars.
              </Paragraph>
              <Hr color="gold" mt={3} />
            </Text>
          </Container>
        </Slide>
        <Slide>
          <GetAQuoteContainer>
            <GetAQuote service={TRANSLATION} />
          </GetAQuoteContainer>
        </Slide>
      </Slider>

      <Box
        sx={{
          bg: 'soft-black',
          color: 'white',
          pt: '3',
          pb: '4',
        }}
      >
        <ServiceHeading id="subtitling">Subtitling</ServiceHeading>
        <Slider>
          <Slide>
            <Container my={5}>
              <Text
                as="article"
                variant="article"
                sx={{
                  maxWidth: '800px',
                  fontSize: [null, null, 3],
                }}
              >
                <Paragraph>
                  Whether your video is made for broadcasting, streaming or simply embedding onto your website, you want
                  the things that are said to be effortlessly understood without taking focus away from the video
                  itself.
                </Paragraph>

                <Paragraph>
                  The art of creating subtitles is, contrary to popular belief, a fine one. Making sure puns don't flop,
                  fitting a rapidly spoken line into a two-second clip, making executive decisions on whether to explain
                  local knowledge literally (at the expense of elegance and ease) – is no easy feat and requires
                  professional expertise.
                </Paragraph>

                <Paragraph>
                  As well as linguistic artistry, the subtitling process calls for plain and simple hard work in the
                  form of using complicated software to transcribe and create time-codes for the captions.
                </Paragraph>

                <Paragraph emphasize>
                  If you would like an estimate for the cost of my services, then swipe to the next page to fill in the
                  basic details of your project, and I'll get back to you to discuss the particulars.
                </Paragraph>
                <Hr color="gold" mt={3} />
              </Text>
            </Container>
          </Slide>
          <Slide>
            <GetAQuoteContainer>
              <GetAQuote service={SUBTITLING} />
            </GetAQuoteContainer>
          </Slide>
        </Slider>
      </Box>
      <Parallax fluid={data.quoteBackgroundImage.childImageSharp.fluid}>
        <Flex
          flexDirection="column"
          alignItems="center"
          px={[4, null, 6]}
          py={[3, null, 5]}
          color="white"
          backgroundColor="rgba(0, 0, 0, 0.6)"
        >
          <RawLink href="https://www.visualdatamedia.com/" mx="auto" width={200} mb={4}>
            <Image
              fluid={data.visualDataLogo.childImageSharp.fluid}
              alt="Visual Data Media"
              title="Visual Data Media"
            />
          </RawLink>
          <Heading fontSize={[4, null, 5]} fontWeight="lighter" textAlign={'center'}>
            <em>We appreciate Idunn's on time delivery with quality work and swift response!</em>
          </Heading>
          <Hr center my={[3, null, 4]} />
          <Text textAlign="center" fontSize={[3, null, 4]} lineHeight="1.5">
            Vinayak Kerekon
            <br /> Lead Production Coordinator
          </Text>
        </Flex>
      </Parallax>
      <ServiceHeading id="localisation">Localisation</ServiceHeading>
      <Slider>
        <Slide>
          <Container my={5}>
            <Text
              as="article"
              variant="article"
              sx={{
                maxWidth: '800px',
                fontSize: [null, null, 3],
              }}
            >
              <Paragraph>
                A localisation service is different to a translation service in that it is closer to transcreation, the
                creative art of taking a message and wrapping it up in a culture other than the one it was originally
                created for.
              </Paragraph>

              <Paragraph>
                When companies decide to offer their products and services in another country, they need to account for
                the differences in culture, history, politics and world views. Expectations are different, and
                everything down to the tone of voice can make or break the reception of a message.
              </Paragraph>

              <Paragraph>
                A new and miraculously efficient mop is something we're all interested in, however, in Norway an advert
                directed solely at female homemakers will most likely not go down a storm. A new approach to
                communicating the same essential message is key.
              </Paragraph>

              <Paragraph emphasize>
                If you would like an estimate for the cost of my services, then swipe to the next page to fill in the
                basic details of your project, and I'll get back to you to discuss the particulars.
              </Paragraph>
              <Hr color="gold" mt={3} />
            </Text>
          </Container>
        </Slide>
        <Slide>
          <GetAQuoteContainer>
            <GetAQuote service={LOCALISATION} />
          </GetAQuoteContainer>
        </Slide>
      </Slider>

      <Box
        sx={{
          bg: 'soft-black',
          color: 'white',
          pt: '3',
          pb: '4',
        }}
      >
        <ServiceHeading id="copywriting">Copywriting</ServiceHeading>
        <Slider>
          <Slide>
            <Container my={5}>
              <Text
                as="article"
                variant="article"
                sx={{
                  maxWidth: '800px',
                  fontSize: [null, null, 3],
                }}
              >
                <Paragraph>
                  The copywriting service builds on localisation, and takes it further. Content is king and while it's
                  all well and good to localise what you already have, it's always prudent to get some tailor-made
                  material added on when approaching a new market.
                </Paragraph>

                <Paragraph>
                  Research and strategic thinking gives a good foundation, whilst a bit of literary flair coupled with
                  idiomatic expressions complete the process. This leaves you with solid content which will strike a
                  chord with the locals.
                </Paragraph>

                <Paragraph>
                  Whether you need copy for your adverts, social media posts, website, app or brochures – I can take
                  your brand and introduce it to the Norwegian audience through a funnel of local knowledge.
                </Paragraph>

                <Paragraph emphasize>
                  If you would like an estimate for the cost of my services, then swipe to the next page to fill in the
                  basic details of your project, and I'll get back to you to discuss the particulars.
                </Paragraph>
                <Hr color="gold" mt={3} />
              </Text>
            </Container>
          </Slide>

          <Slide>
            <GetAQuoteContainer>
              <GetAQuote service={COPYWRITING} />
            </GetAQuoteContainer>
          </Slide>
        </Slider>
      </Box>
    </Layout>
  )
}
