import React from 'react'
import { Label } from '@rebass/forms'
import { Field, useFormikContext } from 'formik'

import RadioButton from '../../radio-button'
import { FIELD_NAME } from './step-form'

export default function RadioField({ value }) {
  const { values } = useFormikContext()

  return (
    <Label>
      <Field name={values[FIELD_NAME]}>
        {({ field, form: { setFieldValue, submitForm } }) => (
          <RadioButton
            {...field}
            value={value}
            checked={field.value === value}
            onClick={() => {
              setFieldValue(field.name, value)
              submitForm()
            }}
          />
        )}
      </Field>
      {value}
    </Label>
  )
}
