import React, { useContext, useRef } from 'react'
import { Field, Formik } from 'formik'
import encodeForm from 'form-urlencoded'
import { Box, Button, Flex, Text } from 'rebass'
import { Input } from '@rebass/forms'

import QuoteContext from './context'
import generateQuote from './quote-generator'
import { quote as quoteFieldName } from './field-names'
import useScrollIntoView from '../../hooks/useScrollIntoView'

export default function ResultsForm() {
  const { state, getFieldValue } = useContext(QuoteContext)
  const ref = useRef()
  useScrollIntoView(ref)

  const { quote, quoteComment } = generateQuote(getFieldValue)

  const values = state.steps.reduce((acc, step) => {
    acc[step.name] = step.value
    return acc
  }, {})

  return (
    <Formik
      initialValues={values}
      onSubmit={data => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encodeForm({ 'form-name': 'quick-quote', ...data }),
        }).then(() => (window.location.href = '/thank-you'))
      }}
    >
      {({ handleSubmit }) => (
        <Box as="form" ref={ref} onSubmit={handleSubmit}>
          <Text pt={[0, 2]} fontSize={4} fontWeight={600} textAlign={['center', 'left']} lineHeight={2}>
            Your estimated quote is{' '}
            <Box
              as="span"
              p={2}
              sx={{
                border: 'solid 3px',
                borderColor: 'gold',
                borderRadius: 999,
              }}
            >
              {quote}
            </Box>
            <Input display="none" name={quoteFieldName} value={quote} />
          </Text>
          {quoteComment.map((text, index) => (
            <Text key={index} mt={2}>
              {text}
            </Text>
          ))}
          {/*<Text my={2}>*/}
          {/*  The above quote is based on my standard rates, however I will always need to look a project over with my own*/}
          {/*  eyes to confirm the price, and am always happy to discuss discounts for the right projects.*/}
          {/*</Text>*/}
          {/*<Text>*/}
          {/*  Fill in the form below to send your details through and I will get back to you within one working day.*/}
          {/*</Text>*/}
          <Box
            sx={{
              display: 'grid',
              gridGap: 3,
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              mt: 3,
            }}
          >
            <Field as={Input} name="name" placeholder="Name" />
            <Field as={Input} name="company" placeholder="Company" />
            <Field as={Input} name="email" placeholder="Email*" type="email" required />
            <Field as={Input} name="phone" placeholder="Phone" type="tel" />
          </Box>

          <Flex width={1} justifyContent="flex-end" pt={3}>
            <Button type="submit" width={['100%', 'unset']}>
              Send
            </Button>
          </Flex>
        </Box>
      )}
    </Formik>
  )
}
