import React from 'react'
import { Input, Label, Radio } from '@rebass/forms'
import { Field, useFormikContext } from 'formik'

import { FIELD_NAME, OTHER } from './step-form'

export default function OtherField() {
  const { values } = useFormikContext()
  const name = values[FIELD_NAME]

  return (
    <Label sx={{ display: 'flex', alignItems: 'center' }}>
      <Field as={Radio} name={name} type="radio" value={OTHER} />
      <Field as={Input} name={OTHER} placeholder="Other..." disabled={values[name] !== OTHER} sx={{ ml: '1' }} />
    </Label>
  )
}
