import { useEffect } from 'react'

export default function useScrollIntoView(ref, options) {
  useEffect(() => {
    if (ref && ref.current && ref.current.scrollIntoViewIfNeeded) {
      ref.current.scrollIntoViewIfNeeded(options)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
