import React from 'react'
import { Box } from 'rebass'

export default function ChevronLeft({ title = 'Chevron left', size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      {...props}
      sx={{
        width: size,
        height: size,
        ...props.sx,
      }}
    >
      <title>{title}</title>
      <g xmlns="http://www.w3.org/2000/svg" transform="translate(0,-952.36218)">
        <path d="m 29.005903,1002.7996 a 5.0005,5.0005 0 0 0 1.2188,2.8437 l 31.99995,37 a 5.0064084,5.0064084 0 1 0 7.5625,-6.5624 l -29.15615,-33.7188 29.15615,-33.7188 a 5.0064086,5.0064086 0 1 0 -7.5625,-6.5624 l -31.99995,37 a 5.0005,5.0005 0 0 0 -1.2188,3.7187 z" />
      </g>
    </Box>
  )
}
