import { Text } from 'rebass'
import React from 'react'

export default function Paragraph({ emphasize, sx, ...props }) {
  return (
    <Text
      as="p"
      sx={{
        breakInside: emphasize && 'avoid',
        textAlign: 'justify',
        fontWeight: emphasize && '600',
        ...sx,
      }}
      {...props}
    />
  )
}
