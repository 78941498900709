import React, { useContext } from 'react'
import { Formik } from 'formik'
import { Box } from 'rebass'

import QuoteContext from '../context'

export const OTHER = '__other__:'
export const FIELD_NAME = '__field_name__'
export const I_DONT_KNOW = "I don't know"

function getInitialValues(value, fieldName) {
  if (typeof value === 'string' && value.indexOf(OTHER) === 0) {
    return {
      [FIELD_NAME]: fieldName,
      [fieldName]: OTHER,
      [OTHER]: value.replace(OTHER, '').trim(),
    }
  } else {
    return {
      [FIELD_NAME]: fieldName,
      [fieldName]: value || '',
      [OTHER]: '',
    }
  }
}

export default function StepForm({ fieldName, children }) {
  const { currentStep, setFieldValue } = useContext(QuoteContext)

  return (
    <Formik
      initialValues={getInitialValues(currentStep.value, fieldName)}
      onSubmit={data => {
        const value = data[fieldName] === OTHER ? `${OTHER} ${data[OTHER]}` : data[fieldName]
        setFieldValue(fieldName, value)
      }}
    >
      {({ handleSubmit }) => (
        <Box as="form" onSubmit={handleSubmit}>
          {children}
        </Box>
      )}
    </Formik>
  )
}
