import React, { useContext, useState } from 'react'
import { Input } from '@rebass/forms'

import QuoteContext from '../context'
import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import SubmitButton from './submit-button'

export default function MinutesStep({ question }) {
  const { currentStep, setFieldValue } = useContext(QuoteContext)
  const [minutes, setMinutes] = useState(currentStep.value || '')

  return (
    <form
      onSubmit={e => {
        setFieldValue(fieldNames.numberOfMinutes, minutes)
        e.preventDefault()
      }}
    >
      <QuestionText>{question}</QuestionText>
      <Input
        type="number"
        name={fieldNames.numberOfMinutes}
        value={minutes}
        min="1"
        onChange={e => setMinutes(e.target.value)}
        required
      />
      <SubmitButton />
    </form>
  )
}
