import * as fieldNames from './field-names'
import { COPYWRITING, LOCALISATION, SUBTITLING, TRANSLATION } from '../utils/constants'
import * as subtitlingServiceTypes from './subtitling-service-types'

const MIN_QUOTE = 50
const TRANSLATION_RATE = 0.1 // per word
const LOCALISATION_RATE = 0.12 // per word

const STANDARD_COMMENT = [
  `The above quote is based on my standard rates, however I will always need to look a project over with my own eyes to
   confirm the price, and am always happy to discuss discounts for the right projects`,
  'Fill in the form below to send your details through and I will get back to you within one working day.',
]

const LOCALISATION_COMMENT = [
  `Every localisation job is different, and estimating a quote for a job can be very difficult. It depends on how much
  research will be necessary, the extent to which a client wants their content tailored for the Norwegian market, and of 
  course – how much content there is.`,
  `The above quote is based on my standard rates and the average amount of time it would take me to work through your word 
  count, however I will always need to look a project over with my own eyes to confirm the price, and am always happy to 
  discuss discounts for the right projects.`,
  `Fill in the form below to send your details through and I will get back to you to discuss further within one working day.`,
]

const STANDARD_INVALID_COMMENT = [
  `Unfortunately, we were not able to calculate you a quote with the information provided. If you fill in your details below
  we will get back to you within one working day.`,
]

function formatQuote(number, comment, invalidComment) {
  const isValidQuote = !isNaN(number)

  return {
    quote: isValidQuote ? `£${Math.max(Math.ceil(number), MIN_QUOTE)}` : '???',
    quoteComment: isValidQuote ? comment : invalidComment,
  }
}

export default function generateQuote(getFieldValue) {
  switch (getFieldValue(fieldNames.service)) {
    case TRANSLATION: {
      const wordCount = parseInt(getFieldValue(fieldNames.wordCount))

      return formatQuote(TRANSLATION_RATE * wordCount, STANDARD_COMMENT, STANDARD_INVALID_COMMENT)
    }
    case SUBTITLING: {
      const subtitlingServices = getFieldValue(fieldNames.subtitlingServices)
      let rate = 0 // per minute
      if (subtitlingServices.indexOf(subtitlingServiceTypes.TRANSLATION) !== -1) {
        rate += 3
      }
      if (subtitlingServices.indexOf(subtitlingServiceTypes.TRANSCRIPTION) !== -1) {
        rate += 7
      }
      if (subtitlingServices.indexOf(subtitlingServiceTypes.TIME_CODING) !== -1) {
        rate += 1
      }

      const episodes = parseInt(getFieldValue(fieldNames.numberOfEpisodes)) || 1
      const minutesPerEpisode = parseInt(getFieldValue(fieldNames.numberOfMinutes))
      const totalMinutes = episodes * minutesPerEpisode

      return formatQuote(totalMinutes * rate, STANDARD_COMMENT, STANDARD_INVALID_COMMENT)
    }
    case LOCALISATION: {
      const termsAndConditionsValue = getFieldValue(fieldNames.termsAndConditions) || {}
      const termsAndConditionsWordCount = parseInt(termsAndConditionsValue['word-count'])
      const localisationWordCount = parseInt(getFieldValue(fieldNames.wordCount))

      let quote
      if (!isNaN(termsAndConditionsWordCount)) {
        quote = TRANSLATION_RATE * termsAndConditionsWordCount
      }

      if (!isNaN(localisationWordCount)) {
        quote = (quote || 0) + LOCALISATION_RATE * localisationWordCount
      }

      return formatQuote(quote, LOCALISATION_COMMENT, STANDARD_INVALID_COMMENT)
    }
    case COPYWRITING: {
      const wordCount = parseInt(getFieldValue(fieldNames.wordCount))

      return formatQuote(wordCount * LOCALISATION_RATE, STANDARD_COMMENT, STANDARD_INVALID_COMMENT)
    }
    default:
      return 'No idea!'
  }
}
