import React, { useContext, useRef } from 'react'
import { Button } from 'rebass'

import QuoteContext from './context'
import Back from '../icons/back'
import useScrollIntoView from '../../hooks/useScrollIntoView'

export default function BackButton({ props }) {
  const ref = useRef()
  useScrollIntoView(ref, false)

  const { back } = useContext(QuoteContext)
  return (
    <Button
      ref={ref}
      color="light-grey"
      display="flex"
      p="3"
      mx="1"
      sx={{
        position: 'absolute',
        bottom: 2,
        border: '3px solid',
        borderRadius: '100px',
        opacity: 1,
        backgroundColor: 'transparent',
        ':hover': {
          opacity: 0.75,
        },
      }}
      onClick={back}
      {...props}
    >
      <Back />
    </Button>
  )
}
