import React, { useContext, useState } from 'react'
import { Input } from '@rebass/forms'

import QuoteContext from '../context'
import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import SubmitButton from './submit-button'

export default function NumberOfEpisodesStep() {
  const { currentStep, setFieldValue } = useContext(QuoteContext)
  const [numberOfEpisodes, setNumberOfEpisodes] = useState(currentStep.value || '')

  return (
    <form
      onSubmit={e => {
        setFieldValue(fieldNames.numberOfEpisodes, numberOfEpisodes)
        e.preventDefault()
      }}
    >
      <QuestionText>How many episodes are there?</QuestionText>
      <Input
        type="number"
        name={fieldNames.numberOfEpisodes}
        min="1"
        value={numberOfEpisodes}
        onChange={e => setNumberOfEpisodes(e.target.value)}
        required
      />
      <SubmitButton />
    </form>
  )
}
