import React, { useContext, useState } from 'react'
import { Checkbox, Label } from '@rebass/forms'

import QuoteContext from '../context'
import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import * as subtitlingServiceTypes from '../subtitling-service-types'
import SubmitButton from './submit-button'

export default function SubtitlingServicesStep() {
  const { currentStep, setFieldValue } = useContext(QuoteContext)
  const [selectedServices, setSelectedServices] = useState(currentStep.value || [])

  return (
    <form
      onSubmit={e => {
        setFieldValue(fieldNames.subtitlingServices, selectedServices)
        e.preventDefault()
      }}
    >
      <QuestionText>Which subtitling services do you require?</QuestionText>
      {Object.values(subtitlingServiceTypes).map(value => (
        <Label key={value}>
          <Checkbox
            name={fieldNames.subtitlingServices}
            value={value}
            checked={selectedServices.indexOf(value) !== -1}
            onChange={() => {
              if (selectedServices.indexOf(value) === -1) {
                setSelectedServices(selectedServices.concat(value))
              } else {
                setSelectedServices(selectedServices.filter(x => x !== value))
              }
            }}
          />
          {value}
        </Label>
      ))}
      <SubmitButton />
    </form>
  )
}
