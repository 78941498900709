import React, { useContext } from 'react'
import { Label } from '@rebass/forms'

import QuoteContext from '../context'
import RadioButton from '../../radio-button'
import * as fieldNames from '../field-names'
import QuestionText from './question-text'

export default function MediaTypeStep() {
  const { currentStep, setFieldValue } = useContext(QuoteContext)

  return (
    <>
      <QuestionText>What is the medium?</QuestionText>
      {['Film', 'TV', 'Advert'].map(value => (
        <Label key={value}>
          <RadioButton
            name={fieldNames.mediaType}
            value={value}
            checked={currentStep.value === value}
            onClick={() => setFieldValue(fieldNames.mediaType, value)}
          />
          {value}
        </Label>
      ))}
    </>
  )
}
