import React from 'react'
import { Input, Label, Radio } from '@rebass/forms'

import * as fieldNames from '../field-names'
import QuestionText from './question-text'
import StepForm from './step-form'
import { Field } from 'formik'
import IDontKnowButton from './i-dont-know-button'
import SubmitButton from './submit-button'

function isWordCountEnabled(form) {
  const termsAndConditions = form.values[fieldNames.termsAndConditions] || {}
  return termsAndConditions.included === 'yes'
}

export default function TermsAndConditionsStep() {
  return (
    <StepForm fieldName={fieldNames.termsAndConditions}>
      <QuestionText>Do you have terms and conditions that need to be translated?</QuestionText>
      <Label>
        <Field as={Radio} name={`${fieldNames.termsAndConditions}.included`} value="no" type="radio" required /> No
      </Label>
      <Label>
        <Field as={Radio} name={`${fieldNames.termsAndConditions}.included`} value="yes" type="radio" required /> Yes
      </Label>
      <Field name={`${fieldNames.termsAndConditions}.word-count`}>
        {({ field, form }) => {
          const disabled = !isWordCountEnabled(form)
          return (
            <>
              <Label mt={4} htmlFor="tac-word-count" sx={disabled && { opacity: 0.5 }}>
                If yes, what is the word count?
              </Label>
              <Input {...field} id="tac-word-count" type="number" disabled={disabled} />
            </>
          )
        }}
      </Field>
      <SubmitButton />
      <IDontKnowButton />
    </StepForm>
  )
}
