import React from 'react'
import { Radio as RebassRadio } from '@rebass/forms'

// EO: Doing this just adds a little bit of feedback to the radio  buttons.
export default function RadioButton({ checked, onClick, ...props }) {
  const handleClick = e => {
    setTimeout(() => {
      onClick(e)
    }, 200)
  }

  return <RebassRadio {...props} defaultChecked={checked} onClick={handleClick} />
}
