import React from 'react'
import { Box } from 'rebass'

export default function Back({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="25 25 50 50"
      fill="currentColor"
      stroke="currentColor"
      {...props}
    >
      <title>Back</title>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M67.7,47.5H38.5l9.2-9.2c1-1,1-2.6,0-3.5c-1-1-2.6-1-3.5,0L30.7,48.2C30.2,48.7,30,49.4,30,50c0,0.6,0.2,1.3,0.7,1.8  l13.4,13.4c1,1,2.6,1,3.5,0c1-1,1-2.6,0-3.5l-9.2-9.2h29.2c1.3,0,2.3-1.1,2.3-2.5S69,47.5,67.7,47.5z"
      />
    </Box>
  )
}
